import React from "react"
import { graphql, PageProps } from "gatsby"
import { Seo, PageTransitionLink } from "../gatsby-components"
import { SingleProjectTemplate } from "../templates"
import {
  getNextProjectLink,
  sanityBlocksToProjectBlocks,
} from "../gatsby-helper"
import { GatsbySanityProject } from "../types"
import { layout } from "../temp-content/layout"

export const SingleProjectGatsbyTemplate = ({
  data,
}: SingleProjectGatsbyTemplateProps) => {
  const { sanityProject: currentGatsbySanityProject } = data
  const allGatsbySanityProjects = data.allSanityProject.nodes

  // Set "nextProject" and "allProjects" Links
  const nextProjectLink = getNextProjectLink(
    allGatsbySanityProjects,
    currentGatsbySanityProject
  )
  const projectPageLink = (
    <PageTransitionLink to="/projekte">Zur Übersicht</PageTransitionLink>
  )

  const blocks = sanityBlocksToProjectBlocks(
    currentGatsbySanityProject.blocks,
    currentGatsbySanityProject
  )

  return (
    <>
      <Seo title={currentGatsbySanityProject.name} />
      <SingleProjectTemplate
        {...{ layout, nextProjectLink, projectPageLink, blocks }}
      />
    </>
  )
}

export type SingleProjectGatsbyTemplateProps = PageProps<{
  sanityProject: GatsbySanityProject
  allSanityProject: {
    nodes: GatsbySanityProject[]
  }
}>

export const query = graphql`
  query SingleProjectQuery($id: String) {
    sanityProject(id: { eq: $id }) {
      id
      name
      scope
      builder
      blocks {
        ... on SanityGallery {
          _type
          images {
            asset {
              gatsbyImageData(backgroundColor: "#000", placeholder: NONE)
            }
          }
        }
        ... on SanityImage {
          _type
          asset {
            gatsbyImageData(backgroundColor: "#000", placeholder: NONE)
          }
        }
        ... on SanityImageCopyright {
          _type
          owners
        }
        ... on SanityTextContent {
          _type
          title
          subline
          content: _rawContent
        }
        ... on SanityTwoColumnImages {
          _type
          imageLeft {
            asset {
              gatsbyImageData(backgroundColor: "#000", placeholder: NONE)
            }
          }
          imageRight {
            asset {
              gatsbyImageData(backgroundColor: "#000", placeholder: NONE)
            }
          }
        }
        ... on SanityVideo {
          _type
          url
        }
        ... on SanityAward {
          _type
          title
          videoUrl
          image {
            asset {
              gatsbyImageData(backgroundColor: "#000", placeholder: NONE)
            }
          }
        }
        ... on SanityLinks {
          _type
          urls
        }
        ... on SanityThreeImageGrid {
          _type
          firstImage {
            asset {
              gatsbyImageData(backgroundColor: "#000", placeholder: NONE)
            }
          }
          secondImage {
            asset {
              gatsbyImageData(backgroundColor: "#000", placeholder: NONE)
            }
          }
          thirdImage {
            asset {
              gatsbyImageData(backgroundColor: "#000", placeholder: NONE)
            }
          }
        }
      }
      projectMembers {
        name
      }
    }
    allSanityProject(sort: { fields: orderRank, order: ASC }) {
      nodes {
        id
        slug {
          current
        }
      }
    }
  }
`

export default SingleProjectGatsbyTemplate
